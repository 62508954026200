<template>
  <div class="msg">{{ messageText }}</div>
</template>

<script>
export default {
  name: 'MsgEntityCollectionEmpty',
  props: {
    message: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: 'data',
    },
  },
  computed: {
    messageText() {
      return this.message
        ? this.message
        : `There are no ${this.name} that fit your criteria, please adjust your filters and try again.`;
    },
  },
};
</script>

<style lang="scss" scoped>
.msg {
  color: #363636;
  font-size: 16px;
  font-weight: 400;

  @media (min-width: 768px) {
    font-size: 24px;
  }
}
</style>
